import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import PropTypes from 'prop-types'
import { H6, colors, Alert, Subtext } from '@PipelineDeals/shared-react-components'

import TeamsCreateWizardSteps from 'admin/Teams/components/CreateWizard/Steps'
import PdfViewer from 'ESignatures/PdfViewer'
import DocumentThumbnails from 'ESignatures/SigningDocuments/DocumentThumbnails'
import { PlaceholdersWrapper, SigningErrorMessage } from 'ESignatures/styles'
import { ModalWrapper, PlaceholdersLeftPanel, PlaceholdersRightPanel, SubmittingSignatureErrorMessage } from 'ESignatures/components/ConfigurePlaceholders/styles'
import DropArea from 'ESignatures/components/ConfigurePlaceholders/DropArea'
import DraggablePlaceholder from 'ESignatures/components/ConfigurePlaceholders/DraggablePlaceholder'
import Loader from 'components/Loader'

import { loadPdf } from 'ESignatures/loadPdf'
import { steps, documentRatio, thumbnailsRatio, fixedModalHeight } from 'ESignatures/components/ConfigurationModal'

export const placeholderWidth = 180
export const placeholderHeight = 40
export const defaultDocumentWidth = 612
export const defaultDocumentHeight = 792
export const placeholderWrapperColors = [colors.reporting.green, colors.reporting.blue]

const generateTempId = () => {
  return `_${Date.now()}-${Math.floor(Math.random() * 1000)}`
}

const ConfigurePlaceholders = ({ signers, generatedDocument, placeholders, updatePlaceholders, submitErrorMessage }) => {
  const [pdfLoaded, setPdfLoaded] = useState(false)
  const [loadingErrorMessage, setLoadingErrorMessage] = useState('')

  const pdfViewerRef = useRef(null)
  const viewerPagesRef = useRef([])
  const pdfThumbnailViewerRef = useRef(null)
  const thumbnailPagesRef = useRef([])

  const placeholdersWrapperRef = useRef(null)
  const stepsHeaderRef = useRef(null)
  const [thumbnailsHeight, setThumbnailsHeight] = useState(0)
  const [rightPanelHeight, setRightPanelHeight] = useState(0)

  const modalBottomMargin = 15
  const modalHeaderHeight = 60
  const modalFooterHeight = 60
  const modalBodyHeight = fixedModalHeight - modalHeaderHeight - modalFooterHeight - modalBottomMargin

  useEffect(async () => {
    try {
      await loadPdf({
        pdfViewerRef,
        viewerPagesRef,
        pdfThumbnailViewerRef,
        thumbnailPagesRef,
        pdfUrl: generatedDocument.s3_public_link,
        width: defaultDocumentWidth,
        height: defaultDocumentHeight,
        thumbnailsRatio,
        documentRatio,
        setPdfLoaded: setPdfLoaded
      })
    } catch (error) {
      setLoadingErrorMessage('We were unable to load the requested document. Please contact supoprt at customercare@pipelinecrm.com')
      throw new Error(error)
    }
  }, [])

  useEffect(() => setTimeout(drawDropAreas, 1), [pdfLoaded, placeholders])

  useLayoutEffect(() => {
    if (placeholdersWrapperRef.current) {
      const placeholdersWrapperHeight = placeholdersWrapperRef.current.getBoundingClientRect().height
      setThumbnailsHeight(modalBodyHeight - placeholdersWrapperHeight + modalBottomMargin)
    }

    if (stepsHeaderRef.current) {
      const stepsHeaderHeight = stepsHeaderRef.current.getBoundingClientRect().height
      setRightPanelHeight(modalBodyHeight - stepsHeaderHeight + modalBottomMargin)
    }
  }, [pdfLoaded, stepsHeaderRef.current, placeholdersWrapperRef.current])

  const renderLoadingErrorMessage = useMemo(() => {
    if (loadingErrorMessage.length) {
      return (
        <SigningErrorMessage>
          <Alert
            alertType="error"
            content={loadingErrorMessage}
          />
        </SigningErrorMessage>
      )
    }
  }, [loadingErrorMessage])

  const renderSubmittingErrorMessage = useMemo(() => {
    if (submitErrorMessage.length) {
      return (
        <SubmittingSignatureErrorMessage>
          <Alert
            alertType="error"
            content={submitErrorMessage}
          />
        </SubmittingSignatureErrorMessage>
      )
    }
  }, [submitErrorMessage])

  const handleDrop = ({ x, y, page, item }) => {
    const { fullName, signerOrder, width, height } = item

    const newPlaceholder = { id: generateTempId(), fullName, x, y, width, height, page, signerOrder }
    updatePlaceholders([...placeholders, newPlaceholder])
  }

  const handlePlaceholderPositionChange = (placeholder, data) => {
    const newPlaceholders = placeholders.map(p => p.id === placeholder.id ? { ...p, x: p.x + data.x, y: p.y + data.y } : p)
    updatePlaceholders(newPlaceholders)
  }

  const removeExistingDropAreas = () => {
  // Remove existing drop areas
    const existingDropAreas = document.getElementsByClassName('drop-area')
    while (existingDropAreas.length > 0) {
      existingDropAreas[0].parentNode.removeChild(existingDropAreas[0])
    }
  }

  const handleRemovePlaceholder = (placeholderToRemove) => {
    const newPlaceholders = placeholders.filter(p => p.id !== placeholderToRemove.id)
    updatePlaceholders(newPlaceholders)
  }

  const drawDropAreas = () => {
    const pages = viewerPagesRef.current
    if (!pages.length) return

    removeExistingDropAreas()

    pages.forEach((page, index) => {
      const div = document.createElement('div')
      div.className = 'drop-area'
      page.appendChild(div)
      const pagePlaceholders = placeholders.filter(placeholder => placeholder.page === index)

      ReactDOM.render(
        <DndProvider backend={HTML5Backend}>
          <DropArea
            onDrop={handleDrop}
            placeholders={pagePlaceholders}
            signers={signers}
            documentRatio={documentRatio}
            page={index}
            handlePlaceholderPositionChange={handlePlaceholderPositionChange}
            onPlaceholderRemove={handleRemovePlaceholder}
          />
        </DndProvider>,
        div)
    })
  }

  const renderDraggablePlaceholders = () => {
    return signers.map(signer => {
      return (
        <div key={signer.order} style={{ marginTop: 15 }}>
          <Subtext>{signer.order === 0 ? '1st' : '2nd'} Signee</Subtext>
          <DraggablePlaceholder signer={signer} documentRatio={documentRatio} />
        </div>
      )
    })
  }

  return (
    <div style={{ height: modalBodyHeight }}>
      <Loader active={!pdfLoaded && !loadingErrorMessage.length} color="black">
        {renderLoadingErrorMessage}
        {pdfLoaded && (
          <DndProvider backend={HTML5Backend}>
            <ModalWrapper>
              <div>
                <PlaceholdersWrapper ref={placeholdersWrapperRef}>
                  <H6>Available signatures</H6>
                  {renderDraggablePlaceholders()}
                </PlaceholdersWrapper>
                <PlaceholdersLeftPanel thumbnailsHeight={thumbnailsHeight}>
                  {pdfLoaded && (
                    <DocumentThumbnails
                      width={defaultDocumentWidth * thumbnailsRatio}
                      pdfViewerRef={pdfThumbnailViewerRef}
                      pagesRef={thumbnailPagesRef}
                      signeeSignatures={[]}
                      signeePlaceholders={[]}
                      mainViewerRef={pdfViewerRef}
                    />
                  )}
                </PlaceholdersLeftPanel>
              </div>
              <div style={{ width: '100%' }}>
                <div className="wizard" style={{ paddingBottom: 0, margin: 0 }} ref={stepsHeaderRef}>
                  <TeamsCreateWizardSteps currentStep={2} steps={steps} />
                </div>
                <div style={{ height: rightPanelHeight, overflowY: 'auto' }}>
                  {renderSubmittingErrorMessage}
                  <PlaceholdersRightPanel>
                    <PdfViewer containerRef={pdfViewerRef} />
                  </PlaceholdersRightPanel>
                </div>
              </div>
            </ModalWrapper>
          </DndProvider>
        )}
      </Loader>
    </div>
  )
}

ConfigurePlaceholders.propTypes = {
  signers: PropTypes.arrayOf(PropTypes.object).isRequired,
  generatedDocument: PropTypes.object.isRequired,
  placeholders: PropTypes.array.isRequired,
  updatePlaceholders: PropTypes.func.isRequired,
  submitErrorMessage: PropTypes.string.isRequired
}

export default ConfigurePlaceholders
