
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Radio } from '@PipelineDeals/shared-react-components'

import TeamsCreateWizardSteps from 'admin/Teams/components/CreateWizard/Steps'
import ESignaturesSignee from 'ESignatures/components/Signee'
import styled from 'styled-components'

const WizardContainer = styled.div`
  margin-left: 0px;
  margin-right: 0px;
`

const StepContent = styled.div`
  border-bottom: none;
`

const StepOneContentHeader = styled.div`
  padding-top: 8px;
  padding-left: 8px;
`

const StepOneRadioContainer = styled.div`
  padding-top: 32px;
  padding-left: 8px;
`

export const steps = ['Add Signers', 'Add Placeholders']
export const thumbnailsRatio = 0.15
export const documentRatio = 1
export const fixedModalHeight = 769

const ConfigureSignees = ({ signers, updateSigners, validSigners, incompleteSigners, signatureOrder, updateSignatureOrder }) => {
  const isSignerInvalid = (signer) => {
    return incompleteSigners.some((incompleteSigner) => incompleteSigner.email === signer.email && incompleteSigner.full_name === signer.full_name)
  }

  const identifierInWords = (identifier) => {
    const words = ['Company', 'Client']
    return words[identifier]
  }

  const canSelectOrder = useMemo(() => validSigners.length >= 2, [validSigners])

  return (
    <WizardContainer className="wizard">
      <TeamsCreateWizardSteps currentStep={1} steps={steps} />
      <StepContent className="step-content smaller-padding-bottom">
        <StepOneContentHeader>
          <p>
            Add signers for your signature request and send email invite. By using this feature you agree to the
            <a href="#"> E-Signature terms of service</a>.
          </p>
        </StepOneContentHeader>
        {signers.map((signer, index) => (
          <ESignaturesSignee
            key={index}
            signer={signer}
            isSignerInvalid={isSignerInvalid(signer)}
            index={index}
            identifier={identifierInWords(index)}
            updateSigner={(i, updatedSigner) => {
              const newSigners = [...signers]
              newSigners[i] = { ...updatedSigner, order: i }
              updateSigners(newSigners)
            }}
          />
        ))}
        <StepOneRadioContainer>
          <h4 style={{ marginBottom: '8px' }}>Order of signatures</h4>
          <div style={{ display: 'flex', gap: '16px' }}>
            <Radio
              name="signatureOrder"
              value="company_first"
              checked={signatureOrder === 'company_first'}
              onChange={() => updateSignatureOrder('company_first')}
              disabled={!canSelectOrder}
              label="Company first"
            />
            <Radio
              name="signatureOrder"
              value="client_first"
              checked={signatureOrder === 'client_first'}
              onChange={() => updateSignatureOrder('client_first')}
              disabled={!canSelectOrder}
              label="Client first"
            />
          </div>
        </StepOneRadioContainer>
      </StepContent>
    </WizardContainer>
  )
}

ConfigureSignees.propTypes = {
  signers: PropTypes.arrayOf(PropTypes.object).isRequired,
  validSigners: PropTypes.arrayOf(PropTypes.object).isRequired,
  incompleteSigners: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateSigners: PropTypes.func.isRequired,
  signatureOrder: PropTypes.string.isRequired,
  updateSignatureOrder: PropTypes.func.isRequired
}

export default ConfigureSignees
