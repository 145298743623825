import React from 'react'
import PropTypes from 'prop-types'

import { Modal, P, PrimaryButton, SecondaryButton } from '@PipelineDeals/shared-react-components'

import styled from 'styled-components'

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

const ConfirmationModal = React.memo(
  ({ title, message, onSubmit, onClose }) => {
    const contentStyles = {
      top: 'auto',
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      width: '450px'
    }

    return (
      <Modal
        isOpen
        title={title}
        onClose={onClose}
        footerTrailingContent={
          <>
            <span style={{ marginRight: '15px' }}>
              <SecondaryButton content="Cancel" onClick={onClose} data-cypress="cancel-button" />
            </span>
            <PrimaryButton content="Confirm" onClick={onSubmit} data-cypress="confirm-button" />
          </>
        }
        contentStyles={contentStyles}
      >
        <CenteredWrapper>
          <P>
            {message}
          </P>
        </CenteredWrapper>
      </Modal>
    )
  }
)

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default ConfirmationModal
