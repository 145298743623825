import React, { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useDrag } from 'react-dnd'

import { placeholderWrapperColors, placeholderWidth, placeholderHeight } from './index'
import { PlaceholderWrapper } from 'ESignatures/components/ConfigurePlaceholders/styles'

export const DRAGGABLE_BOX_TYPE = 'BOX'

export const DraggablePlaceholder = ({ signer, documentRatio }) => {
  const elementRef = useRef(null)
  const signerOrder = useMemo(() => signer.order, [signer])

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAGGABLE_BOX_TYPE,
    item: (monitor) => {
      const element = elementRef.current
      if (!element) return

      const rect = element.getBoundingClientRect()
      const initialClientOffset = monitor.getInitialClientOffset()
      const initialX = initialClientOffset.x - rect.left
      const initialY = initialClientOffset.y - rect.top

      return { signerOrder, height: rect.height, width: rect.width, x: rect.left, y: rect.top, initialX, initialY }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  const opacity = isDragging ? 0.4 : 1
  return (
    <PlaceholderWrapper
      className="signature-placeholder"
      documentRatio={documentRatio}
      placeholderHeight={placeholderHeight}
      placeholderWidth={placeholderWidth}
      backgroundColor={placeholderWrapperColors[signer.order]}
      style={{ opacity }}
      ref={(node) => {
        elementRef.current = node
        drag(node)
      }}
    >
      <span className="signature-placeholder-text">{signer.full_name}</span>
    </PlaceholderWrapper>
  )
}

DraggablePlaceholder.propTypes = {
  signer: PropTypes.object.isRequired,
  documentRatio: PropTypes.number
}

export default DraggablePlaceholder
