import React from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import { useDrop } from 'react-dnd'
import { placeholderWrapperColors, placeholderHeight, placeholderWidth } from 'ESignatures/components/ConfigurePlaceholders'
import { PlaceholderWrapper, RemovePlaceholderButton } from 'ESignatures/components/ConfigurePlaceholders/styles'
import { DRAGGABLE_BOX_TYPE } from 'ESignatures/components/ConfigurePlaceholders/DraggablePlaceholder'

const style = {
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0
}

const DropArea = ({ onDrop, placeholders, signers, page, handlePlaceholderPositionChange, documentRatio, onPlaceholderRemove }) => {
  const dropAreaRef = React.useRef(null)

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DRAGGABLE_BOX_TYPE,
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset()
      const sourceClientOffset = monitor.getSourceClientOffset()
      const dropAreaRect = dropAreaRef.current.getBoundingClientRect()

      if (onDrop && clientOffset && sourceClientOffset) {
        const deltaX = clientOffset.x - dropAreaRect.left
        const deltaY = clientOffset.y - dropAreaRect.top

        const topLeftX = deltaX - item.initialX
        const topLeftY = deltaY - item.initialY

        onDrop({ x: topLeftX, y: topLeftY, page, item: item })
      }
      return { page: page, name: 'dropArea' }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  const isActive = canDrop && isOver
  let opacity = 1
  const backgroundColor = 'transparent'

  if (isActive) {
    opacity = 0.2
  } else if (canDrop) {
    opacity = 0.2
  }

  drop(dropAreaRef)

  const renderPlaceholder = (placeholder) => {
    const placeholderSigner = signers.find(signer => signer.order === placeholder.signerOrder)

    return (
      <div style={{ position: 'absolute', left: placeholder.x, top: placeholder.y }}>
        <PlaceholderWrapper
          className="signature-placeholder"
          documentRatio={documentRatio}
          placeholderHeight={placeholderHeight}
          placeholderWidth={placeholderWidth}
          backgroundColor={placeholderWrapperColors[placeholder.signerOrder]}
        >
          <RemovePlaceholderButton
            onClick={() => onPlaceholderRemove(placeholder)}
            className="remove-button"
            baseColor={placeholderWrapperColors[placeholder.signerOrder]}
          >
            <i className="fa fa-times" />
          </RemovePlaceholderButton>
          <span className="signature-placeholder-text">{placeholderSigner.full_name}</span>
        </PlaceholderWrapper>
      </div>
    )
  }

  return (
    <div ref={dropAreaRef} style={{ ...style, backgroundColor: backgroundColor, opacity: opacity }} data-testid="dropArea">
      {placeholders.map((placeholder, index) => (
        <Draggable
          bounds="parent"
          key={index}
          onStop={(_, data) => handlePlaceholderPositionChange(placeholder, data)}
        >
          {renderPlaceholder(placeholder)}
        </Draggable>
      ))}
    </div>
  )
}

DropArea.propTypes = {
  onDrop: PropTypes.func.isRequired,
  placeholders: PropTypes.array.isRequired,
  signers: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  handlePlaceholderPositionChange: PropTypes.func.isRequired,
  documentRatio: PropTypes.number,
  onPlaceholderRemove: PropTypes.func.isRequired
}

export default DropArea
