import styled from 'styled-components'

import { colors } from '@PipelineDeals/shared-react-components'
import { LeftPanel, RightPanel } from 'ESignatures/styles'

export const ModalWrapper = styled.div`
  display: flex;
  height: 100%;
  margin-top: -14px;
  margin-left: -14px;
  background-color: ${colors.structure.primary};
`

export const PlaceholdersLeftPanel = styled(LeftPanel)`
  min-width: 220px;
  height: ${props => props.thumbnailsHeight}px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`

export const PlaceholdersRightPanel = styled(RightPanel)`
  padding: 0px;
  padding-top: 20px;
  overflow-y: hidden;
  gap: 15px;
  align-items: center;
  position: relative;
`

export const RemovePlaceholderButton = styled.button`
  position: absolute;
  top: 4px;
  right: -1px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  color: ${props => props.baseColor};
  filter: brightness(0.7);
`

export const PlaceholderWrapper = styled.div`
  height: ${props => props.documentRatio * props.placeholderHeight}px;
  width: ${props => props.documentRatio * props.placeholderWidth}px;
  word-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  color: ${colors.text.primary};
  border: 1px dashed ${props => props.backgroundColor};
  position: relative;
  z-index: 1;
  font-size: calc(14px * ${props => props.documentRatio});
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${props => props.backgroundColor};
    opacity: 0.3;
    z-index: 0;
  }

  span {
    padding-top: 3px;
  }

  .remove-button {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &:hover .remove-button {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  &:active .remove-button {
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }
`

export const SubmittingSignatureErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`
