import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf'

pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://unpkg.com/pdfjs-dist@4.8.69/legacy/build/pdf.worker.min.mjs'

const buildPages = ({ page, viewerRef, pagesRef, renderPromises, width, height, isThumb = false }) => {
  const container = viewerRef.current
  if (!container) return

  const viewport = page.getViewport({ scale: 1 })
  const scale = Math.min(width / viewport.width, height / viewport.height)
  const scaledViewport = page.getViewport({ scale })

  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  canvas.height = scaledViewport.height
  canvas.width = scaledViewport.width

  const pdfPage = document.createElement('div')
  pdfPage.className = 'pdf-page'
  if (isThumb && page.pageNumber === 1) {
    pdfPage.classList.add('pdf-page-thumb-active')
  }
  pdfPage.appendChild(canvas)
  container.appendChild(pdfPage)

  const addPageRef = (element) => {
    if (element) {
      pagesRef.current.push(element)
    }
  }
  addPageRef(pdfPage)

  const renderTask = page.render({
    canvasContext: context,
    viewport: scaledViewport
  }).promise

  renderPromises.push(renderTask)
}

export const scrollToPage = (pageNumber, viewerRef, thumbnailViewerRef) => {
  const container = viewerRef.current
  const thumbnailContainer = thumbnailViewerRef?.current
  if (!container) return

  const pages = container.getElementsByClassName('pdf-page')
  if (pageNumber < 1 || pageNumber > pages.length) return

  if (thumbnailContainer) {
    const thumbnails = thumbnailContainer.getElementsByClassName('pdf-page')
    Array.from(thumbnails).forEach(thumb => {
      thumb.classList.remove('pdf-page-thumb-active')
    })
    thumbnails[pageNumber - 1].classList.add('pdf-page-thumb-active')
  }

  const targetPage = pages[pageNumber - 1]
  targetPage.scrollIntoView({ behavior: 'smooth' })
}

export const loadPdf = async ({
  pdfViewerRef,
  viewerPagesRef,
  pdfThumbnailViewerRef,
  thumbnailPagesRef,
  pdfUrl,
  width,
  height,
  setPdfLoaded,
  thumbnailsRatio,
  documentRatio = 1
}) => {
  try {
    const pdf = await pdfjsLib.getDocument(pdfUrl).promise
    const renderPromises = []

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      pdf.getPage(pageNum).then(page => {
        pdfViewerRef && buildPages({
          page,
          viewerRef: pdfViewerRef,
          pagesRef: viewerPagesRef,
          renderPromises,
          width: width * documentRatio,
          height: height * documentRatio,
          isThumb: false
        })
        pdfThumbnailViewerRef && buildPages({
          page,
          viewerRef: pdfThumbnailViewerRef,
          pagesRef: thumbnailPagesRef,
          renderPromises,
          width: width * thumbnailsRatio,
          height: height * thumbnailsRatio,
          isThumb: true
        })
      })

      Promise.all(renderPromises).then(() => {
        setPdfLoaded(true)
      })
    }
  } catch (error) {
    throw new Error(error)
  }
}
