import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Modal, SecondaryButton, PrimaryButton, TertiaryButton } from '@PipelineDeals/shared-react-components'

import ConfigurePlaceholders from 'ESignatures/components/ConfigurePlaceholders'
import ConfigureSignees from 'ESignatures/components/ConfigureSignees'

import { emailValidator } from 'add_edit_modals/FormField/validators'

import fetcher from 'modules/fetcher'
import styled from 'styled-components'

const FooterTrailingContent = styled.div`
  display: flex;
  gap: 15px;
`

export const steps = ['Add Signers', 'Add Placeholders']
export const thumbnailsRatio = 0.15
export const documentRatio = 1
export const fixedModalHeight = 769

export const emailAddressValidator = (email) => {
  if (!email?.length) return { valid: false, errorMsg: 'Required' }

  return emailValidator({ fieldValue: email })
}

const ESignaturesConfigurationModal = ({ generatedDocument, recordTitle, isOpen, onClose, afterSave, initialSigners, onGoToSettings }) => {
  const [signatureOrder, setSignatureOrder] = useState('company_first')
  const [signers, setSigners] = useState([])
  const [placeholders, setPlaceholders] = useState([])
  const [currentStep, setCurrentStep] = useState(1)
  const [submittingForm, setSubmittingForm] = useState(false)
  const [submitErrorMessage, setSubmitErrorMessage] = useState('')

  useEffect(() => {
    setSigners(initialSigners)
  }, [initialSigners])

  const updateSigners = (newSigners) => { setSigners(newSigners) }
  const updateSignatureOrder = (newSignatureOrder) => { setSignatureOrder(newSignatureOrder) }

  const handleNextStep = () => {
    setSubmitErrorMessage('')

    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleGoToSettings = () => {
    window.open('/admin/modern/e_signature', '_blank')
    onGoToSettings()
    onClose()
  }

  const handleFormSubmit = () => {
    setSubmittingForm(true)
    setSubmitErrorMessage('')

    const scaleFactor = 1 / documentRatio

    const placeholdersWithRequiredAttributes = placeholders.map(({ id, ...rest }) => rest)
    const newPlaceholders = placeholdersWithRequiredAttributes.map((placeholder) => {
      return {
        ...placeholder,
        x: parseInt(placeholder.x * scaleFactor),
        y: parseInt(placeholder.y * scaleFactor),
        width: parseInt(placeholder.width * scaleFactor),
        height: parseInt(placeholder.height * scaleFactor)
      }
    })

    fetcher(`/api/v3/generated_documents/${generatedDocument.id}/e_signatures_document`, {
      method: 'POST',
      body: JSON.stringify({
        e_signatures_document: {
          signers: validSigners,
          placeholders: newPlaceholders
        }
      })
    }).then(() => {
      onClose()
      afterSave()
    }).catch(() => {
      setSubmitErrorMessage('We were unable to send the document for e-signatures. Please try again later.')
    }).finally(() => {
      setSubmittingForm(false)
    })
  }

  const updatePlaceholders = (newPlaceholders) => {
    setPlaceholders(newPlaceholders)
  }

  const incompleteSigners = useMemo(() => {
    return signers.filter(signer => (
      signer.full_name?.length && !emailAddressValidator(signer.email).valid) || (!signer.full_name?.length && signer.email?.length)
    )
  }, [signers])

  const validSigners = useMemo(() => {
    const validRecords = signers.filter(signer => signer.full_name?.length && emailAddressValidator(signer.email).valid)
    const orderedValidRecords = signatureOrder === 'company_first' ? validRecords : validRecords.reverse()

    return orderedValidRecords.map((signer, index) => ({ ...signer, order: index }))
  }, [signers, signatureOrder])

  const canContinueToSecondStep = useMemo(() => {
    return validSigners.length > 0 && incompleteSigners.length === 0
  }, [signers])

  const canSubmitForm = useMemo(() => {
    const eachValidSignerHasPlaceholder = validSigners.every(signer => {
      return placeholders.some(placeholder => placeholder.signerOrder === signer.order)
    })

    return eachValidSignerHasPlaceholder
  }, [validSigners, placeholders])

  const footerLeadingContent = useMemo(() => {
    if (currentStep === 1) {
      return <TertiaryButton content="E-Sign Settings" onClick={handleGoToSettings} />
    } else {
      return <SecondaryButton content="Back" onClick={() => setCurrentStep(1)} />
    }
  }, [currentStep])

  const footerTrailingContentAction = useMemo(() => {
    if (currentStep < steps.length) {
      return <PrimaryButton disabled={!canContinueToSecondStep} content="Continue to Next Step" onClick={handleNextStep} />
    } else {
      return <PrimaryButton disabled={!canSubmitForm || submittingForm} content="Send Document" onClick={handleFormSubmit} />
    }
  }, [currentStep, canContinueToSecondStep, canSubmitForm, placeholders, submittingForm])

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <ConfigureSignees
            signers={signers} updateSigners={updateSigners} validSigners={validSigners}
            incompleteSigners={incompleteSigners} signatureOrder={signatureOrder} updateSignatureOrder={updateSignatureOrder}
          />
        )
      case 2:
        return (
          <ConfigurePlaceholders
            generatedDocument={generatedDocument} signers={validSigners}
            placeholders={placeholders} updatePlaceholders={updatePlaceholders} submitErrorMessage={submitErrorMessage}
          />
        )
      default:
        return null
    }
  }

  return (
    <Modal
      title={`Request E-Signatures for ${recordTitle}`}
      isOpen={isOpen}
      onClose={onClose}
      footerLeadingContent={footerLeadingContent}
      footerTrailingContent={
        <FooterTrailingContent>
          <SecondaryButton content="Cancel" onClick={onClose} />
          {footerTrailingContentAction}
        </FooterTrailingContent>
      }
      contentStyles={{
        bottom: 'auto',
        left: 'auto',
        right: 'auto',
        top: 'auto',
        width: currentStep === 1 ? '900px' : '1200px',
        height: `${fixedModalHeight}px`
      }}
    >
      {renderStepContent()}
    </Modal>
  )
}

ESignaturesConfigurationModal.propTypes = {
  recordTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  afterSave: PropTypes.func.isRequired,
  initialSigners: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGoToSettings: PropTypes.func.isRequired,
  generatedDocument: PropTypes.object.isRequired
}

export default ESignaturesConfigurationModal
