import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { H6, colors } from '@PipelineDeals/shared-react-components'

import PdfViewer from 'ESignatures/PdfViewer'
import PageStatus from './PageStatus'
import { scrollToPage } from 'ESignatures/loadPdf'

import { ThumbnailsWrapper, ThumbnailHeader } from '../styles'

const DocumentThumbnails = ({ width, pdfViewerRef, pagesRef, signeeSignatures, signeePlaceholders, mainViewerRef }) => {
  useEffect(() => {
    let cleanupFn = () => {}

    setTimeout(() => {
      const pages = pagesRef.current

      if (!pages?.length) {
        return
      }

      decoratePages()
      cleanupFn = addClickHandlers(pages)
    }, 1)

    return () => {
      cleanupFn()
    }
  }, [mainViewerRef, pdfViewerRef, signeeSignatures])

  const decoratePages = () => {
    const pages = pagesRef.current
    if (!pages.length) return

    addPageNumbers(pages)
    addSignMarkers(pages)
  }

  const addPageNumbers = (pages) => {
    pages.forEach((page, index) => {
      const pageNumberDiv = document.createElement('div')
      pageNumberDiv.className = 'signature-page-number'
      pageNumberDiv.textContent = index + 1
      pageNumberDiv.style.color = colors.text.tertiary
      page.appendChild(pageNumberDiv)
    })
  }

  const addSignMarkers = (pages) => {
    pages.forEach((page, index) => {
      const pagePlaceholders = signeePlaceholders.filter(placeholder => placeholder.document_position.page === index)
      if (!pagePlaceholders.length) return

      const isSigned = pagePlaceholders.every(placeholder => signeeSignatures.find(signature => signature.placeholderId === placeholder.id))

      if (pagePlaceholders.length) {
        const div = document.createElement('div')
        div.className = 'signature-page-status'
        div.style.width = `${width}px`
        page.appendChild(div)

        ReactDOM.render(<PageStatus isSigned={isSigned} />, div)
      }
    })
  }

  const addClickHandlers = (pages) => {
    const handlePageClick = (index) => () => {
      scrollToPage(index + 1, mainViewerRef, pdfViewerRef)
      pages[index].scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    pages.forEach((page, index) => {
      page.style.cursor = 'pointer'
      const clickHandler = handlePageClick(index)
      page.addEventListener('click', clickHandler)
      page._clickHandler = clickHandler
    })

    return () => {
      pages.forEach((page) => {
        if (page._clickHandler) {
          page.removeEventListener('click', page._clickHandler)
          delete page._clickHandler
        }
      })
    }
  }

  return (
    <ThumbnailsWrapper>
      <ThumbnailHeader><H6>Contract Pages</H6></ThumbnailHeader>
      <PdfViewer containerRef={pdfViewerRef} width={width} />
    </ThumbnailsWrapper>

  )
}

DocumentThumbnails.propTypes = {
  signeePlaceholders: PropTypes.array.isRequired,
  signeeSignatures: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  pdfViewerRef: PropTypes.object.isRequired,
  pagesRef: PropTypes.object.isRequired,
  mainViewerRef: PropTypes.object.isRequired
}

export default DocumentThumbnails
