import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const Green = styled.span`
  color: green;
  padding-right: 7px;
`

const Red = styled.span`
  color: red;
  padding-right: 7px;
`

const PageStatus = ({ isSigned }) => {
  const signed = <div><Green><i className="far fa-check" /></Green>Signed</div>
  const notSigned = <div><Red><i className="far fa-xmark" /></Red>Not signed</div>
  return (
    <div>
      {isSigned ? signed : notSigned}
    </div>
  )
}

PageStatus.propTypes = {
  isSigned: PropTypes.bool.isRequired
}

export default PageStatus
