import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'

const TeamsCreateWizardSteps = ({ currentStep, steps }) =>
  <div className="step-nav-wrapper">
    <div className="step-nav">
      <div className="nav-row">
        {steps.map((step, index) => {
          const number = index + 1

          return (
            <div key={number} className="nav-item">
              <div className={classNames('btn-circle', { active: currentStep === number })}>
                {number}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  </div>

TeamsCreateWizardSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired
}

export default TeamsCreateWizardSteps
