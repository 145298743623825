import styled from 'styled-components'
import { colors } from '@PipelineDeals/shared-react-components'

export const LeftPanel = styled.div`
  min-width: 320px;
  box-sizing: border-box;
  box-shadow: inset -1px 0px 0px ${colors.structure.quaternary};
  overflow-y: scroll;
  background-color: #fff;
  padding: 15px;
`

export const PlaceholdersWrapper = styled.div`
  box-sizing: border-box;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: inset -1px 0px 0px ${colors.structure.quaternary};
  border-bottom: 1px solid ${colors.structure.quaternary};
`

export const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: scroll;
  padding: 30px;
`

export const ThumbnailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
`

export const SigningErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`
export const ThumbnailHeader = styled.div`
  margin-bottom: 7px;
`

export const SubmittedSignaturesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 7px;
`
