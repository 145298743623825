import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal, SelectInput, SecondaryButton, P } from '@PipelineDeals/shared-react-components'
import { getVisibleActiveUsers } from 'VisibleUsers/selectors'
import styled from 'styled-components'

const IntroContainer = styled.div`
  margin: 8px 0px 24px 0px;
  padding-left: 15px;
  padding-right: 15px;
`

const ESignaturesSelectUser = ({ isOpen, onClose, onSelectUser, overrideSelectUserData }) => {
  const accountUsers = useSelector(getVisibleActiveUsers)
  const [userOptions, setUserOptions] = useState([])

  useEffect(() => {
    const sourceOfTruth = overrideSelectUserData || accountUsers

    const options = sourceOfTruth.map(({ id, full_name, email }) => ({
      value: id,
      label: full_name,
      email: email
    }))
    setUserOptions(options)
  }, [accountUsers, overrideSelectUserData])

  const handleUserChange = (option) => {
    onSelectUser(option)
  }

  return (
    <Modal
      title="Select a Pipeline User"
      isOpen={isOpen}
      onClose={onClose}
      footerTrailingContent={(
        <>
          <SecondaryButton content="Close" onClick={onClose} />
        </>
      )}
      contentStyles={{
        bottom: 'auto',
        left: 'auto',
        right: 'auto',
        top: 'auto',
        width: '400px'
      }}
    >
      <IntroContainer>
        <P>Search user</P>
        <SelectInput
          placeholder="Type user name here"
          onChange={handleUserChange}
          options={userOptions}
          searchable
        />

      </IntroContainer>
    </Modal>
  )
}

ESignaturesSelectUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  overrideSelectUserData: PropTypes.array
}

export default ESignaturesSelectUser
