import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const PdfContainer = styled.div`
  margin: auto;

  .pdf-page {
    outline: 1px solid #ddd;
    position: relative;
    margin-bottom: 20px;
  }
`

const PdfViewer = ({ containerRef }) => {
  return <PdfContainer ref={containerRef} />
}

PdfViewer.propTypes = {
  containerRef: PropTypes.object.isRequired
}

export default PdfViewer
